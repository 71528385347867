import { ReactNode } from "react";

function createClassName(
  fullRound: boolean,
  dark: boolean,
  className: string,
  mode: "primary" | "secondary"
) {
  const round = fullRound ? "rounded-full" : "rounded-[7px]";

  const darkTheme =
    "bg-[radial-gradient(circle,#FF4B00_0%,#E56029_100%)] shadow-[0_4.63px_0_#581A00] border-[#FF4B00] border-[2px]";
  const lightTheme =
    "bg-[#FF4B00] shadow-[0_3px_0_#D43F01] border-[#FF4B00] border-[2px]";

  const theme = dark ? darkTheme : lightTheme;

  const alternative = mode === "primary" ? "text-white" : "bg-white text-black";

  return `w-[269px] px-2 py-1.5 self-center ${round} flex items-center relative ${theme} ${alternative} ${className}`;
}

export function LinkButton(
  props: {
    fullRound?: boolean;
    dark?: boolean;
    mode?: "primary" | "secondary";
    chevronRight?: boolean;
    chevronLeft?: boolean;
    children: ReactNode;
  } & React.ComponentProps<"a">
) {
  const {
    fullRound,
    dark,
    children,
    className,
    mode,
    chevronLeft,
    chevronRight,
    ...rest
  } = props;

  const _className = createClassName(
    fullRound ?? false,
    dark ?? false,
    className ?? "",
    mode ?? "primary"
  );

  return (
    <a {...rest} className={_className}>
      <ButtonInner
        mode={mode}
        chevronRight={chevronRight}
        chevronLeft={chevronLeft}
      >
        {children}
      </ButtonInner>
    </a>
  );
}

export function Button(
  props: {
    fullRound?: boolean;
    dark?: boolean;
    mode?: "primary" | "secondary";
    chevronRight?: boolean;
    chevronLeft?: boolean;
    children: ReactNode;
  } & React.ComponentProps<"button">
) {
  const {
    fullRound,
    dark,
    children,
    className,
    mode,
    chevronLeft,
    chevronRight,
    ...rest
  } = props;

  const _className = createClassName(
    fullRound ?? false,
    dark ?? false,
    className ?? "",
    mode ?? "primary"
  );

  return (
    <button {...rest} className={_className}>
      <ButtonInner
        mode={mode}
        chevronRight={chevronRight}
        chevronLeft={chevronLeft}
      >
        {children}
      </ButtonInner>
    </button>
  );
}

function ButtonInner({
  children,
  mode = "primary",
  chevronLeft,
  chevronRight,
}: {
  children: ReactNode;
  mode?: "primary" | "secondary";
  chevronRight?: boolean;
  chevronLeft?: boolean;
}) {
  const chevronColor = mode === "primary" ? "white" : "black";
  const chevronRightFile = `icon-chevron-right-${chevronColor}.svg`;

  return (
    <>
      {chevronLeft && (
        <img
          src={`/static/img/${chevronRightFile}`}
          className="w-[10.21px] h-[14.83px] absolute left-1.5 top-auto bottom-auto transform rotate-180"
          alt="左矢印"
        />
      )}
      <span className="text-[18.5px] font-semibold grow text-center">
        {children}
      </span>
      {chevronRight && (
        <img
          src={`/static/img/${chevronRightFile}`}
          className="w-[10.21px] h-[14.83px] absolute right-1.5 top-auto bottom-auto"
          alt="右矢印"
        />
      )}
    </>
  );
}
